/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
/** Interconvert between Qiskit operators and PennyLane operators.*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    hr: "hr",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://github.com/Qiskit/qiskit"
  }, "Qiskit"), " is an open-source software development kit for programming quantum computers, including quantum hardware devices from IBM. The core Qiskit package contains several basic quantum programming primitives,\nwith additional functionalities—such as simulators and applications—provided by community-maintained projects as part of ", React.createElement(_components.a, {
    href: "https://github.com/Qiskit-Extensions/"
  }, "Qiskit Extensions"), ". Qiskit 1.0 was released in February 2024, representing the library's first stable release. Qiskit is pronounced \"kiss-kit\" 😘, though you may also hear it called \"kwis-kit\"."), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.p, null, "PennyLane provides several options for users to connect and work with Qiskit:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Run PennyLane programs on IBM quantum computer hardware by making use of the ", React.createElement(_components.a, {
    href: "https://docs.pennylane.ai/projects/qiskit/en/latest/"
  }, "PennyLane-Qiskit plugin"), "."), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://docs.pennylane.ai/en/stable/code/api/pennylane.from_qiskit.html"
  }, "Convert"), " Qiskit circuits into PennyLane circuits (or vice versa)."), "\n", React.createElement(_components.li, null, "Create OpenQASM circuits from PennyLane, or create PennyLane programs from OpenQASM (also useful for interacting with other quantum computing platforms or SDKs)."), "\n"), "\n", "\n", React.createElement(_components.p, null, "By making use of these options, users can convert their entire code from one library to another, or orchestrate more complex workflows which require multiple libraries\nused in tandem (e.g., adding PennyLane’s advanced quantum machine learning functionality to a circuit originally coded in Qiskit)."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
